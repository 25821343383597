import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>School Runs</h2>
          <p>
          At IConnectCars, we provide transportation services to schools and educational institutions throughout Surrey.
          <br/>
          Regrettably, the school run frequently overlaps with the work commute, causing mornings to be less seamless than desired. 
          Considering this, why not allow IConnectCars to alleviate the strain of the school run? 
          Ensuring your child's safe journey to and from school is of paramount importance. With a team of skilled drivers, we consistently transport children securely. 
          Through this consistent practice, we've established trust with both authorities and customers alike.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title=""
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
