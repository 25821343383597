import React from "react"

export const CallToAction = ({ data }) => {
  if (!data) return null

  return (
    <div id="earn">
      <div className="container ">
        <div className="row">

          <div className="col-xs-12 col-md-6">
            <h2>{data.title}</h2>
            <p>{data.paragraph}</p>
            <a href="#contact" className="btn btn-custom btn-lg page-scroll" a={data.link}>
              {data.button}
            </a>
          </div>

          <div className=" col-xs-12 col-md-6">
            <img className="img-responsive" src={data.image} alt="cta" />
          </div>
        </div>
      </div>
      </div>
  )
}
